import React from "react"
import { Link } from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import styled from "styled-components"
import { device } from "../queries"
import Img from "gatsby-image"
import { useStaticQuery, staticQuery } from "gatsby"

const Maincontainer = styled.div`
  width: 100%;
  padding: 0 auto;
  background-color: #ededed;
  box-shadow: inset 0 0 10px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  @media ${device.laptopL}{
      padding: 0 10px;
      margin-top: 25px;
    }

  h1{
    display: inline-block;
    position: inherit;
    text-align: left;
    font-weight: 900;
    font-size: 56px;
    color: var(--dark-text);
    @media ${device.laptopL}{

    }
    @media ${device.laptop}{
      font-size: 50px;

    }
    @media ${device.tablet}{
    }
    @media ${device.tablet}{
      font-size: 36px;

    }
    @media ${device.mobileL}{
      font-size: 32px;
    }
    @media ${device.mobileL}{
      font-size: 28px;
    }
  }

`

const Overflowcontainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  display: relative;
  overflow: visible;
`
const Innercontainer = styled.div`
margin: 10px 0;
overflow: visible;
    
`
const MainImgContainer = styled.div`
  display: inline-block;
  position: unset;
  width: 200px;
  align-items: center;
  display: flex;
  overflow: visible;
  z-index: 110;
  overflow: visible;
  @media ${device.laptopL}{
      width: 180px;
    }
    @media ${device.laptop}{
      width: 160px;
    }
    @media ${device.tablet}{
      width: 140px;
    }
    @media ${device.tablet}{
      width: 140px;
    }
    @media ${device.mobileL}{
      width: 120px;
    }
    @media ${device.mobileM}{
      width: 100px;
    }

`

const Styledimg = styled.div`
width: 200px;
height: 200px;
position: absolute;
z-index: 110;
overflow: visible;
@media ${device.laptopL}{
      width: 180px;
      height: 180px;
    }
    @media ${device.laptop}{
      width: 160px;
      height: 160px;
    }
    @media ${device.tablet}{
      width: 140px;
      height: 140px;
    }
    @media ${device.tablet}{
      width: 140px;
      height: 140px;
    }
    @media ${device.mobileL}{
      width: 120px;
      height: 120px;
    }
    @media ${device.mobileM}{
      width: 100px;
      height: 100px;
    }

`

const Headerimage = ({something, className}) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "diensticonen/recycling.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      anotherImage: file(relativePath: { eq: "recycling/recycling.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      softdes: file(relativePath: { eq: "datavernietiging/softwarematige_dataverwijdering.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600 maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mechdes: file(relativePath: { eq: "datavernietiging/mechanische_dataverwijdering.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
      },
      site {
          siteMetadata {
            vacatures {
              name
              link
            }
          }
      }
    }
  `)
  const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />,<Img className={className} fluid={data.anotherImage.childImageSharp.fluid}/>, <Img className={className} fluid={data.softdes.childImageSharp.fluid} />, <Img className={className} fluid={data.mechdes.childImageSharp.fluid} />]
  return image[something];
  }


const PageHeader = ({children, title,image, bannerimage}) => {
  return(
  <Maincontainer>
    {bannerimage}
    <Overflowcontainer>

    <Innercontainer>
     <h1>{title}</h1>
     {children}
    </Innercontainer>
    <MainImgContainer>
      <Styledimg>{image}</Styledimg>
    </MainImgContainer>
    </Overflowcontainer>
  </Maincontainer>
  )
}

export default PageHeader



