import React from "react"
import { Link } from "gatsby"
import PageHeader from "../components/pageheader"
import Padding from "../components/padding"
import Pagelayout1 from "../components/layout"
import styled from "styled-components"
import Layout from "../layouts"
import SEO from "../components/seo"
import { device } from "../queries"


const Margin = styled.div`
  height: 60px;
`

const SecondPage = () => (
  <Layout>
    <SEO title="Datavernietiging" />
    <Margin/>
    <PageHeader title="DATAVERNIETIGING"></PageHeader>
    <Padding/>
    <Pagelayout1></Pagelayout1>
    <Padding/>
  </Layout> 
)

export default SecondPage


