import React from "react"
import styled from "styled-components"
import {device} from "../queries"



const Pad = styled.div`
    @media ${device.tablet}{
        height: 100px;

    }
    @media ${device.mobileL}{
        height: 70px;
    }

    width: 100%;
    height: 175px;

`

export default function Padding(){
    return(
        <Pad/>  
    );
}